import { Units, UnitToPretty } from "../../Common/Enums";
import { ValueWithBounds } from "../LiveViewGauges";
import ScaleLine, { ScaleLineColor, TickMarkDescriptor } from "./ScaleLine";

export default function AirQualityScaleLine({ valueWithBounds }: { valueWithBounds: ValueWithBounds }) {

  let initialColor: ScaleLineColor = "red";
  let endValue = 3200;
  let tickMarkers: TickMarkDescriptor[] = [{
    colorTillNextTickMark: "red",
    tickValue: 2000,
    hideTickMark: true
  }];

  if (valueWithBounds && valueWithBounds.hasBounds()) {
    initialColor = "green";
    endValue = valueWithBounds.optimal_bounds![1] * 1.6
    tickMarkers = [{
      colorTillNextTickMark: "red",
      tickValue: valueWithBounds.optimal_bounds![1],
    }];
  }

  return (
    <ScaleLine
      startValue={0}
      endValue={endValue}
      colorTillFirstTickMark={initialColor}
      currentValue={valueWithBounds.value}
      totalWidth={150}
      hideEndTickMark={true}
      hideEndTickLabel
      tickMarkers={tickMarkers}
      units={UnitToPretty(Units.PPM)}
    ></ScaleLine>
  );
}
