import React, { useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import logo from "./logo.svg";
import "./App.css";

import BuildingOverview from "./pages/BuildingOverview/BuildingOverview";

import solidGauge from "highcharts/modules/solid-gauge";
import highchartsMore from "highcharts/highcharts-more";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PlayGround from "./pages/TestPage/TestPage";
import RoomPage from "./pages/RoomPage/RoomPage";
import PilotSelector from "./components/PilotSelector";
highchartsMore(Highcharts);
solidGauge(Highcharts);

const options: Highcharts.Options = {
  chart: {
    type: "solidgauge",
  },
  title: {
    text: "My chart",
  },
  series: [
    {
      type: "gauge",
      data: [1],
    },
  ],
};

export const PilotContext = React.createContext(null);


function App(props: HighchartsReact.Props) {

  const [pilot, setPilot] = useState(0);
  //const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  return (
    <div className="App">
      {!pilot ?
        <PilotSelector selectedPilot={pilot} pilotSelected={setPilot} /> :
        <BrowserRouter>
          <Routes>
            <Route index element={<BuildingOverview />} />
            <Route path="room/:roomId" element={<RoomPage />} />
          </Routes>
        </BrowserRouter>
      }
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload!
        </p>
        <h1 className="text-3xl font-bold underline">Hello world!</h1>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
          {...props}
        />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
