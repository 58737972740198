import InsightsGauges from "../../components/InsightsGauges";
import PercentageScaleLine from "../../components/ScaleLines/PercentageScaleLine";
import {
    colors,
    Divider,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
} from "@mui/material";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Metric } from "../../Common/Enums";
import { GetRequestOptions } from "../../Common/Requests";

interface InsightsTabProps {
    roomId: string;
    dateFrom: Date;
    dateTo: Date;
}



export default function LiveViewTab(props: InsightsTabProps) {
    const [dateFrom, setDateFrom] = useState<Date>(props.dateFrom);
    const [dateTo, setDateTo] = useState<Date>(props.dateTo);
    const [identifiedIssues, setIdentifiedIssues] = useState<{ [key: number]: { "hours": string[], "days": string[], "months": string[] } }>({});


    useEffect(() => {
        const cancellationToken = new AbortController();
        if (!!props.roomId) {
            fetch(
                `/api/rooms/issues_detection?room_id=${props.roomId}&date_from=${dateFrom.toLocaleDateString("sv")}&date_to=${dateTo.toLocaleDateString("sv")}'`, { ...GetRequestOptions(), signal: cancellationToken.signal }
            )
                .then((response) => response.json())
                .then((data: { metrics: { [key: number]: { "hours": string[], "days": string[], "months": string[] } } }) => {
                    setIdentifiedIssues(data.metrics);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
        return () => cancellationToken.abort();
    }, [props.roomId, dateTo, dateFrom])

    return (<>
        <div className="flex flex-col h-full">
            <div className="flex-initial">
                <div className="flex justify-start gap-8 my-3 mx-1 justify-between">
                    <div className="text-xs text-left">Mean and deviation of metric</div>
                    <div>
                        <span className="mr-3">Date from:</span>
                        <span className="inline-block border-solid border-2 border-gray-500 mr-8">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                selected={dateFrom}
                                onChange={(date) => setDateFrom(date ?? new Date())}
                                dateFormat="yyyy-MM-dd"
                            />
                        </span>
                        <span className="mr-3">Date to:</span>
                        <span className="inline-block border-solid border-2 border-gray-500">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                selected={dateTo}
                                onChange={(date) => setDateTo(date ?? new Date())}
                                dateFormat="yyyy-MM-dd"
                            />
                        </span>
                    </div>
                </div>
                <InsightsGauges props={{
                    air_quality: {
                        mean: 1200, abnormalPercentage: 45
                    },
                    temperature: {
                        mean: 24, abnormalPercentage: 73
                    },
                    humidity: {
                        mean: 79, abnormalPercentage: 3
                    },
                    acoustic_comfort: {
                        mean: 16, abnormalPercentage: 92
                    },
                    visual_comfort: {
                        mean: 400, abnormalPercentage: 28
                    }
                }} />
            </div>

            <div className="grid grid-cols-5 grid-rows-4 justify-items-center items-center border-solid border-2 border-gray-400 mt-4" style={{ gridTemplateRows: "repeat(4, auto)" }}>
                <div className="col-start-1 col-end-6 row-start-1 row-end-2 justify-start w-full mb-4">
                    <p className="text-left mx-2 mt-3 mb-1 font-bold">Identified issues:</p>
                    <Divider
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: colors.grey[700],
                            marginLeft: 5,
                            marginRight: 5
                        }}
                    />
                </div>
                <div className="col-start-1 col-end-2 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.AirQuality] && identifiedIssues[Metric.AirQuality].hours ? identifiedIssues[Metric.AirQuality].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.AirQuality] && identifiedIssues[Metric.AirQuality].days ? identifiedIssues[Metric.AirQuality].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.AirQuality] && identifiedIssues[Metric.AirQuality].months ? identifiedIssues[Metric.AirQuality].months.join(", ") : "No issues"}</p>
                </div>

                <div className="col-start-1 col-end-6 row-start-3 row-end-4 justify-start w-full mb-4">
                    <p className="text-left mx-2 mt-3 font-bold">Proposed actions:</p>
                    <Divider
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: colors.grey[700],
                            marginLeft: 5,
                            marginRight: 5
                        }}
                    />
                </div>

                <div className="col-start-1 col-end-2 row-start-4 row-end-5 pb-4">
                    <p>Soft measure 1</p>
                </div>

                <div className="col-start-2 col-end-3 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.Temperature] && identifiedIssues[Metric.Temperature].hours ? identifiedIssues[Metric.Temperature].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.Temperature] && identifiedIssues[Metric.Temperature].days ? identifiedIssues[Metric.Temperature].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.Temperature] && identifiedIssues[Metric.Temperature].months ? identifiedIssues[Metric.Temperature].months.join(", ") : "No issues"}</p>
                </div>

                <div className="col-start-2 col-end-3 row-start-4 row-end-5 pb-4">
                    <p>Behavioral measure 1</p>
                </div>


                <div className="col-start-3 col-end-4 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.Humidity] && identifiedIssues[Metric.Humidity].hours ? identifiedIssues[Metric.Humidity].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.Humidity] && identifiedIssues[Metric.Humidity].days ? identifiedIssues[Metric.Humidity].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.Humidity] && identifiedIssues[Metric.Humidity].months ? identifiedIssues[Metric.Humidity].months.join(", ") : "No issues"}</p>
                </div>

                <div className="col-start-3 col-end-4 row-start-4 row-end-5 pb-4">
                    <p>Renovation measure 1</p>
                </div>

                <div className="col-start-4 col-end-5 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.AcousticComfort] && identifiedIssues[Metric.AcousticComfort].hours ? identifiedIssues[Metric.AcousticComfort].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.AcousticComfort] && identifiedIssues[Metric.AcousticComfort].days ? identifiedIssues[Metric.AcousticComfort].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.AcousticComfort] && identifiedIssues[Metric.AcousticComfort].months ? identifiedIssues[Metric.AcousticComfort].months.join(", ") : "No issues"}</p>
                </div>

                <div className="col-start-4 col-end-5 row-start-4 row-end-5 pb-4">
                    <p>No measures</p>
                </div>
                <div className="col-start-5 col-end-6 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.VisualComfort] && identifiedIssues[Metric.VisualComfort].hours ? identifiedIssues[Metric.VisualComfort].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.VisualComfort] && identifiedIssues[Metric.VisualComfort].days ? identifiedIssues[Metric.VisualComfort].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.VisualComfort] && identifiedIssues[Metric.VisualComfort].months ? identifiedIssues[Metric.VisualComfort].months.join(", ") : "No issues"}</p>
                </div>
                <div className="col-start-5 col-end-6 row-start-4 row-end-5 pb-4">
                    <p>Soft measure 2</p>
                </div>
            </div>
            {/* <div className="border-solid border-2 border-gray-400 flex-col flex flex-auto overflow-y-auto">
                <p className="text-left mx-2 mt-3 mb-1 font-bold">Identified issues:</p>
                <Divider
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: colors.grey[700],
                        marginLeft: 5,
                        marginRight: 5
                    }}
                />
                <p className="text-left mx-2 mt-3 font-bold">Proposed actions:</p>
                <Divider
                    style={{
                        borderBottomWidth: 1,
                        borderBottomColor: colors.grey[700],
                        marginLeft: 5,
                        marginRight: 5
                    }}
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus molestie dolor, ut elementum est malesuada non. Mauris interdum felis id mauris facilisis, quis semper nulla bibendum. Sed lacinia leo at viverra feugiat. Suspendisse fringilla dui id malesuada laoreet. Nulla vitae massa aliquet, maximus felis sed, rutrum dolor. Donec cursus ligula eget tellus facilisis, quis ullamcorper velit gravida. Donec facilisis augue lectus, ac efficitur augue malesuada eu. Sed sollicitudin arcu eget massa euismod, vel fringilla tortor placerat. Donec libero nulla, consectetur sed metus eu, laoreet mollis metus. Fusce ac mattis justo, sit amet tempor erat.

                Sed id rutrum velit, nec commodo mi. Mauris accumsan neque eleifend feugiat molestie. Fusce imperdiet dictum aliquam. Sed posuere lacinia nisl ac bibendum. Sed fermentum in mauris ac aliquam. Vivamus vitae lectus elit. Nullam faucibus fringilla risus, vel gravida risus consectetur non. Sed eget imperdiet neque. Vivamus porta, lorem et gravida interdum, magna ex pharetra elit, in lobortis neque urna ut justo.

                Sed orci purus, malesuada eu ultricies vel, elementum vitae ex. Quisque justo dui, eleifend a ex sed, vulputate vulputate nunc. Donec ullamcorper nisi ut mi cursus, sed lobortis mauris aliquam. Duis vel justo non justo ornare tempus. Nulla diam urna, auctor eu ipsum non, consequat finibus lectus. Pellentesque auctor eros sit amet sem pharetra efficitur. Etiam ultricies vestibulum facilisis. Etiam nec fermentum orci. Proin venenatis vestibulum magna, at mollis nisi placerat ac. Phasellus cursus sodales urna, vel mattis justo gravida sed.

                Duis eget lacinia elit. Nam sollicitudin eros sit amet ante suscipit, vel varius turpis auctor. Proin maximus, magna at malesuada varius, ex elit condimentum ipsum, nec tempor lectus est eget metus. Nulla non sollicitudin diam. Etiam ullamcorper pharetra sem, faucibus molestie sem finibus nec. Nulla purus nisl, dapibus sit amet nibh nec, accumsan ullamcorper massa. Phasellus est enim, gravida vitae fringilla vitae, ornare sed felis.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus molestie dolor, ut elementum est malesuada non. Mauris interdum felis id mauris facilisis, quis semper nulla bibendum. Sed lacinia leo at viverra feugiat. Suspendisse fringilla dui id malesuada laoreet. Nulla vitae massa aliquet, maximus felis sed, rutrum dolor. Donec cursus ligula eget tellus facilisis, quis ullamcorper velit gravida. Donec facilisis augue lectus, ac efficitur augue malesuada eu. Sed sollicitudin arcu eget massa euismod, vel fringilla tortor placerat. Donec libero nulla, consectetur sed metus eu, laoreet mollis metus. Fusce ac mattis justo, sit amet tempor erat.

                Sed id rutrum velit, nec commodo mi. Mauris accumsan neque eleifend feugiat molestie. Fusce imperdiet dictum aliquam. Sed posuere lacinia nisl ac bibendum. Sed fermentum in mauris ac aliquam. Vivamus vitae lectus elit. Nullam faucibus fringilla risus, vel gravida risus consectetur non. Sed eget imperdiet neque. Vivamus porta, lorem et gravida interdum, magna ex pharetra elit, in lobortis neque urna ut justo.

                Sed orci purus, malesuada eu ultricies vel, elementum vitae ex. Quisque justo dui, eleifend a ex sed, vulputate vulputate nunc. Donec ullamcorper nisi ut mi cursus, sed lobortis mauris aliquam. Duis vel justo non justo ornare tempus. Nulla diam urna, auctor eu ipsum non, consequat finibus lectus. Pellentesque auctor eros sit amet sem pharetra efficitur. Etiam ultricies vestibulum facilisis. Etiam nec fermentum orci. Proin venenatis vestibulum magna, at mollis nisi placerat ac. Phasellus cursus sodales urna, vel mattis justo gravida sed.

                Duis eget lacinia elit. Nam sollicitudin eros sit amet ante suscipit, vel varius turpis auctor. Proin maximus, magna at malesuada varius, ex elit condimentum ipsum, nec tempor lectus est eget metus. Nulla non sollicitudin diam. Etiam ullamcorper pharetra sem, faucibus molestie sem finibus nec. Nulla purus nisl, dapibus sit amet nibh nec, accumsan ullamcorper massa. Phasellus est enim, gravida vitae fringilla vitae, ornare sed felis.

                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus molestie dolor, ut elementum est malesuada non. Mauris interdum felis id mauris facilisis, quis semper nulla bibendum. Sed lacinia leo at viverra feugiat. Suspendisse fringilla dui id malesuada laoreet. Nulla vitae massa aliquet, maximus felis sed, rutrum dolor. Donec cursus ligula eget tellus facilisis, quis ullamcorper velit gravida. Donec facilisis augue lectus, ac efficitur augue malesuada eu. Sed sollicitudin arcu eget massa euismod, vel fringilla tortor placerat. Donec libero nulla, consectetur sed metus eu, laoreet mollis metus. Fusce ac mattis justo, sit amet tempor erat.

                Sed id rutrum velit, nec commodo mi. Mauris accumsan neque eleifend feugiat molestie. Fusce imperdiet dictum aliquam. Sed posuere lacinia nisl ac bibendum. Sed fermentum in mauris ac aliquam. Vivamus vitae lectus elit. Nullam faucibus fringilla risus, vel gravida risus consectetur non. Sed eget imperdiet neque. Vivamus porta, lorem et gravida interdum, magna ex pharetra elit, in lobortis neque urna ut justo.

                Sed orci purus, malesuada eu ultricies vel, elementum vitae ex. Quisque justo dui, eleifend a ex sed, vulputate vulputate nunc. Donec ullamcorper nisi ut mi cursus, sed lobortis mauris aliquam. Duis vel justo non justo ornare tempus. Nulla diam urna, auctor eu ipsum non, consequat finibus lectus. Pellentesque auctor eros sit amet sem pharetra efficitur. Etiam ultricies vestibulum facilisis. Etiam nec fermentum orci. Proin venenatis vestibulum magna, at mollis nisi placerat ac. Phasellus cursus sodales urna, vel mattis justo gravida sed.

                Duis eget lacinia elit. Nam sollicitudin eros sit amet ante suscipit, vel varius turpis auctor. Proin maximus, magna at malesuada varius, ex elit condimentum ipsum, nec tempor lectus est eget metus. Nulla non sollicitudin diam. Etiam ullamcorper pharetra sem, faucibus molestie sem finibus nec. Nulla purus nisl, dapibus sit amet nibh nec, accumsan ullamcorper massa. Phasellus est enim, gravida vitae fringilla vitae, ornare sed felis.


            </div> */}
        </div>
    </>);
}