var myHeaders = new Headers();
myHeaders.append("X-Pilot", "1");

export function GetRequestOptions() {
    return {
        method: 'GET',
        headers: myHeaders,
    };
}


