import { Units, UnitToPretty } from "../../Common/Enums";
import { ValueWithBounds } from "../LiveViewGauges";
import ScaleLine, { TickMarkDescriptor } from "./ScaleLine";

export default function VisualComfortScaleLine({ valueWithBounds }: { valueWithBounds: ValueWithBounds }) {

  let endValue = 1200;
  let tickMarkers: TickMarkDescriptor[] = [{
    colorTillNextTickMark: "red",
    tickValue: 500,
  }, {
    colorTillNextTickMark: "red",
    tickValue: 1000,
  }];

  if (valueWithBounds && valueWithBounds.hasBounds()) {
    endValue = valueWithBounds.optimal_bounds![1] * 1.4;
    tickMarkers = [];
    tickMarkers.push({
      colorTillNextTickMark: "green",
      tickValue: valueWithBounds.optimal_bounds![0],
    });
    tickMarkers.push({
      colorTillNextTickMark: "red",
      tickValue: valueWithBounds.optimal_bounds![1],
    });
  }

  return (
    <ScaleLine
      startValue={0}
      endValue={endValue}
      colorTillFirstTickMark="red"
      currentValue={valueWithBounds.value}
      totalWidth={150}
      tickMarkers={tickMarkers}
      hideEndTickMark={true}
      hideEndTickLabel={true}
      units={UnitToPretty(Units.Lux)}
    ></ScaleLine>
  );
}
