import "./ScaleLine.css";

export type ScaleLineColor = "red" | "orange" | "green" | "yellow" | "yellowgreen" | "gradientright" | "gradientleft";

interface ScaleLineSegmentProps {
  start: number;
  end: number;
  color: ScaleLineColor;
  pointerValue?: number;
  hideStartMark?: boolean;
  startLabel?: number | string | null;
  hideEndMark?: boolean;
  endLabel?: number | string;
  topLabel?: string;
}

function ScaleLineSegment({
  start,
  end,
  color,
  pointerValue,
  hideStartMark,
  startLabel,
  hideEndMark,
  endLabel,
  topLabel
}: ScaleLineSegmentProps) {
  const backgroundcolor = (() => {
    switch (color) {
      case "red":
        return "scalelinered";
      case "orange":
        return "scalelineorange";
      case "yellow":
        return "scalelineyellow";
      case "yellowgreen":
        return "scalelineyellowgreen";
      case "green":
        return "scalelinegreen";
      case "gradientright":
        return "scalelinegradientright";
      case "gradientleft":
        return "scalelinegradientleft";
      default:
        return "scalelineblack";
    }
  })();
  // todo make 2 const with css
  const range = end - start;

  const pointerPosition =
    pointerValue !== undefined &&
    Math.min(Math.max(((pointerValue - start) / (end - start)) * 100, 0), 100);

  return (
    <div style={{ display: "block", alignItems: "end", flex: `${range} 0 0` }}>
      {topLabel && <div style={{ position: "absolute", fontSize: "x-small" }}>{topLabel}</div>}
      <div
        className="triangle-down"
        style={{ position: "relative", left: `${pointerPosition}%`, visibility: (pointerValue == undefined ? "hidden" : "visible") }}
      ></div>
      <div className="scalelinesegment">
        {startLabel === undefined ? (
          <></>
        ) : (
          <p
            style={{
              position: "absolute",
              transform: `translate(-50%,  10px)`,
            }}
          >
            {startLabel}
          </p>
        )}
        {endLabel === undefined ? (
          <></>
        ) : (
          <p
            style={{
              position: "absolute",
              transform: `translate(50%,  10px)`,
              right: 0,
            }}
          >
            {endLabel}
          </p>
        )}
        {!hideStartMark && (
          <div className={`scalelinesegmentedge ${backgroundcolor}`}></div>
        )}
        <div
          className={`scalelinesegmenthorizontalpart ${backgroundcolor}`}
          style={{ width: "100%" }}
        ></div>
        {!hideEndMark && (
          <div className={`scalelinesegmentedge ${backgroundcolor}`}></div>
        )}
      </div>
    </div>
  );
}

export interface TickMarkDescriptor {
  colorTillNextTickMark: ScaleLineColor;
  tickValue: number;
  hideTickMark?: boolean;
  hideTickLabel?: boolean;
}

export default function ScaleLine({
  startValue,
  hideStartTickMark,
  hideStartTickLabel,
  endValue,
  hideEndTickMark,
  hideEndTickLabel,
  colorTillFirstTickMark,
  currentValue,
  totalWidth,
  tickMarkers,
  units
}: {
  startValue: number;
  hideStartTickMark?: boolean;
  hideStartTickLabel?: boolean;
  endValue: number;
  hideEndTickMark?: boolean;
  hideEndTickLabel?: boolean;
  colorTillFirstTickMark: ScaleLineColor;
  currentValue: number | undefined;
  totalWidth: number;
  tickMarkers: TickMarkDescriptor[];
  units?: string;
}) {
  let segments: ScaleLineSegmentProps[] = [];

  let currentSegment: ScaleLineSegmentProps = {
    start: startValue,
    color: colorTillFirstTickMark,
    end: endValue,
    startLabel: hideStartTickLabel ? undefined : startValue,
    hideStartMark: hideStartTickMark,
    topLabel: units
  };

  const sortedTickMarkers = tickMarkers
    .filter((x) => x.tickValue >= startValue && x.tickValue < endValue)
    .toSorted((t1, t2) => t1.tickValue - t2.tickValue);

  for (let i = 0; i <= sortedTickMarkers.length; i++) {
    segments.push(currentSegment);

    if (i === sortedTickMarkers.length) {
      currentSegment.end = endValue;
    } else {
      let next = sortedTickMarkers[i];
      currentSegment.end = next.tickValue;
      currentSegment = {
        start: next.tickValue,
        color: next.colorTillNextTickMark,
        end: -1,
        startLabel: next.hideTickLabel ? null : next.tickValue,
        // TODO hide tickmarks
      };
    }
  }

  const lastSegment = segments[segments.length - 1];

  lastSegment.hideEndMark = hideEndTickMark;
  lastSegment.endLabel = hideEndTickLabel ? undefined : endValue;

  if (currentValue !== undefined) {
    (segments.find((x) => x.end > currentValue) ?? lastSegment).pointerValue =
      currentValue;
  }

  return (
    <div
      style={{
        width: totalWidth,
        maxWidth: totalWidth,
      }}
    >
      <div className="scalelinecontainer">
        {segments.map((x, index) => {
          return (
            <ScaleLineSegment
              key={index}
              start={x.start}
              end={x.end}
              color={x.color}
              pointerValue={x.pointerValue}
              hideStartMark={x.hideStartMark}
              startLabel={x.startLabel}
              hideEndMark={x.hideEndMark}
              endLabel={x.endLabel}
              topLabel={x.topLabel}
            />
          );
        })}
      </div>
    </div >
  );
}
