import { MenuItem, Select } from "@mui/material"
import { Metric } from "../Common/Enums"
import { RoomSensor } from "../Common/Types"

interface PilotSelectorProps {
    selectedPilot: number,
    pilotSelected: (pilot: number) => void
}


export default function PilotSelector(props: PilotSelectorProps) {
    return (<>
        <Select
            value={props.selectedPilot || ''}
            onChange={(event) => props.pilotSelected(event.target.value as Metric)}
            sx={{
                width: `185px`,
                ".MuiOutlinedInput-input": {
                    padding: 0
                }
            }}
        >
            <MenuItem key={0} value={0}>None</MenuItem>
            <MenuItem key={1} value={1}>Ellet</MenuItem>
        </Select>
    </>)
}