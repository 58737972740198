import { Outlet, Link } from "react-router-dom";

import { colors, Divider, Card, CardContent } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { Units, UnitToPretty } from "../../Common/Enums";

function Gauges() {
  return (
    <div className="flex grow">
      <div className="mx-3">
        <Gauge
          width={100}
          height={100}
          value={600}
          valueMax={600}
          text={({ value }) => `${value}\n${UnitToPretty(Units.PPM)}`}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontWeight: "bold",
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: colors.yellow[300],
            },
            //   [`& .${gaugeClasses.referenceArc}`]: {
            //     fill: theme.palette.text.disabled,
            //   },
          })}
        />
        <div className="text-xs">Air Quality</div>
      </div>
      <div className="inline-flex bg-yellow-100 flex-col">
        <div className="inline-flex">
          <div className="mx-3">
            <Gauge
              width={100}
              height={100}
              value={21}
              valueMax={21}
              text={({ value }) => `${value}\n${UnitToPretty(Units.DegreesCelsius)}`}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontWeight: "bold",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: colors.green[300],
                },
              }}
            />
            <div className="text-xs">Temperature</div>
          </div>
          <div className="mx-3">
            <Gauge
              width={100}
              height={100}
              value={72}
              valueMax={72}
              text={({ value }) => `${value}\n${UnitToPretty(Units.Percent)}`}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontWeight: "bold",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: colors.red[200],
                },
              }}
            />
            <div className="text-xs">Humidity</div>
          </div>
        </div>
        <Divider
          variant="middle"
          style={{
            margin: 6,
            borderBottomWidth: 1,
            borderBottomColor: colors.grey[500],
          }}
        />
        <div className="block text-sm mb-1">Thermal Comfort</div>
      </div>
      <div className="mx-3">
        <Gauge
          width={100}
          height={100}
          value={20}
          valueMax={20}
          text={({ value }) => `${value}\n${UnitToPretty(Units.Decibel)}`}
          sx={{
            [`& .${gaugeClasses.valueText}`]: {
              fontWeight: "bold",
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: colors.green[300],
            },
          }}
        />
        <div className="text-xs">Acoustic Comfort</div>
      </div>
      <div className="mx-3">
        <Gauge
          width={100}
          height={100}
          value={100}
          valueMax={100}
          text={({ value }) => `${value}\n${UnitToPretty(Units.Lux)}`}
          sx={{
            [`& .${gaugeClasses.valueText}`]: {
              fontWeight: "bold",
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: colors.green[300],
            },
          }}
        />
        <div className="text-xs">Visual Comfort</div>
      </div>
    </div>
  );
}

type RoomProperties = {
  name: string;
  function: string;
  floor: number;
  id: number;
};

let rooms: RoomProperties[] = [
  { id: 1, name: "Rooom 1", function: "Entrance Hall", floor: 0 },
  { id: 2, name: "Rooom 2", function: "Office", floor: 0 },
  { id: 3, name: "Rooom 3", function: "Office", floor: 0 },
  { id: 4, name: "Rooom 4", function: "Office", floor: 0 },
  { id: 5, name: "Rooom 5", function: "Office", floor: 1 },
  { id: 6, name: "Rooom 6", function: "Office", floor: 1 },
  { id: 7, name: "Rooom 7", function: "Office", floor: 1 },
  { id: 8, name: "Rooom 8", function: "Office", floor: 1 },
  { id: 9, name: "Rooom 9", function: "Office", floor: 2 },
  { id: 10, name: "Rooom 10", function: "Office", floor: 2 },
  { id: 11, name: "Rooom 11", function: "Office", floor: 2 },
  { id: 12, name: "Rooom 12", function: "Office", floor: 2 },
];

interface BasicCardProps {
  room: RoomProperties;
}

function BasicCard({ room }: BasicCardProps) {
  return (
    <Link to="/room/24">
      <Card
        sx={{
          width: 205,
          height: 100,
          border: 1,
          borderColor:
            room.floor === 0
              ? colors.red[500]
              : room.floor === 1
                ? colors.green[500]
                : colors.orange[500],
          userSelect: "none",
        }}
        className="shrink-0"
        raised
      >
        <CardContent>
          <div>{room.name}</div>
          <div>{room.function}</div>
          <div>Floor {room.floor}</div>
        </CardContent>
        {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </Card>
    </Link>
  );
}

function Rooms() {
  return (
    <>
      <div className="flex flex-wrap justify-around gap-4 m-2">
        {rooms.map((x) => (
          <BasicCard key={x.id} room={x} />
        ))}
      </div>
    </>
  );
}

export default function BuildingOverview() {
  return (
    <div className="min-w-[1000px] overflow-x-auto">
      <div className="flex my-2">
        <div className="min-w-64 mx-3">
          <div className="font-bold">IEQ Web Service​</div>
          <div>ELLET building in Tripodon street,</div>
          <div>Athens, Greece</div>
        </div>
        <div className="flex-5">
          <Gauges />
        </div>
      </div>
      <Divider
        variant="middle"
        style={{
          borderBottomColor: colors.grey[500],
        }}
      />
      <Rooms />
    </div>
  );
}
