import { MenuItem, Select } from "@mui/material"
import { Metric } from "../Common/Enums"
import { RoomSensor } from "../Common/Types"

interface MetricsDropdownSelectProps {
    selectedMetric: Metric,
    roomSensors: RoomSensor[],
    metricSelected: (metric: Metric) => void
}


export default function LiveViewTab(props: MetricsDropdownSelectProps) {
    return (<>
        <Select
            disabled={props.selectedMetric === undefined}
            value={props.selectedMetric || ''}
            onChange={(event) => props.metricSelected(event.target.value as Metric)}
            sx={{
                width: `185px`,
                ".MuiOutlinedInput-input": {
                    padding: 0
                }
            }}
        >
            <MenuItem disabled={props.roomSensors.filter(x => x.metric === Metric.AirQuality).length === 0} key={Metric.AirQuality} value={Metric.AirQuality}>Air Quality</MenuItem>
            <MenuItem disabled={props.roomSensors.filter(x => x.metric === Metric.Temperature).length === 0} key={Metric.Temperature} value={Metric.Temperature}>Temperature</MenuItem>
            <MenuItem disabled={props.roomSensors.filter(x => x.metric === Metric.Humidity).length === 0} key={Metric.Humidity} value={Metric.Humidity}>Humidity</MenuItem>
            <MenuItem disabled={props.roomSensors.filter(x => x.metric === Metric.AcousticComfort).length === 0} key={Metric.AcousticComfort} value={Metric.AcousticComfort}>Acoustic Comfort</MenuItem>
            <MenuItem disabled={props.roomSensors.filter(x => x.metric === Metric.VisualComfort).length === 0} key={Metric.VisualComfort} value={Metric.VisualComfort}>Visual Comfort</MenuItem>
        </Select>
    </>)
}