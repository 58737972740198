import { Units, UnitToPretty } from "../../Common/Enums";
import { ValueWithBounds } from "../LiveViewGauges";
import ScaleLine, { TickMarkDescriptor } from "./ScaleLine";

export default function TemperatureScaleLine({ valueWithBounds }: { valueWithBounds: ValueWithBounds }) {

  let startValue = 10;
  let endValue = 40;
  let tickMarkers: TickMarkDescriptor[] = [{
    colorTillNextTickMark: "red",
    tickValue: 15,
  }, {
    colorTillNextTickMark: "red",
    tickValue: 35,
  }];

  if (valueWithBounds && valueWithBounds.hasBounds()) {
    startValue = Math.min(valueWithBounds.optimal_bounds![0] - 5, 10);
    endValue = Math.max(valueWithBounds.optimal_bounds![1] + 5, 40);
    tickMarkers = [];
    if (10 < valueWithBounds.optimal_bounds![0]) {
      tickMarkers.push({
        colorTillNextTickMark: "red",
        tickValue: 15,
        hideTickMark: true
      });
    }
    tickMarkers.push({
      colorTillNextTickMark: "green",
      tickValue: valueWithBounds.optimal_bounds![0],
    });
    tickMarkers.push({
      colorTillNextTickMark: "red",
      tickValue: valueWithBounds.optimal_bounds![1],
    });
    if (30 > valueWithBounds.optimal_bounds![0]) {
      tickMarkers.push({
        colorTillNextTickMark: "red",
        tickValue: 35,
        hideTickMark: true
      });
    }
  }

  return (
    <ScaleLine
      startValue={startValue}
      endValue={endValue}
      colorTillFirstTickMark="red"
      currentValue={valueWithBounds.value}
      totalWidth={150}
      hideEndTickMark={true}
      hideStartTickMark
      hideStartTickLabel
      hideEndTickLabel
      tickMarkers={tickMarkers}
      units={UnitToPretty(Units.DegreesCelsius)}
    ></ScaleLine >
  );
}
