import { Units, UnitToPretty } from "../../Common/Enums";
import ScaleLine, { TickMarkDescriptor } from "./ScaleLine";

export default function PercentageScaleLine({ value }: { value?: number }) {

    const tickMarkers: TickMarkDescriptor[] = [{
        colorTillNextTickMark: "orange",
        tickValue: 20,
    },
    {
        colorTillNextTickMark: "yellow",
        tickValue: 40,
    },
    {
        colorTillNextTickMark: "yellowgreen",
        tickValue: 60,
    },
    {
        colorTillNextTickMark: "green",
        tickValue: 80,
    }];

    return (
        <ScaleLine
            startValue={0}
            endValue={100}
            colorTillFirstTickMark="red"
            currentValue={value}
            totalWidth={150}
            hideEndTickMark={false}
            hideStartTickMark={false}
            hideStartTickLabel={false}
            hideEndTickLabel={false}
            tickMarkers={tickMarkers}
            units={UnitToPretty(Units.Percent)}
        ></ScaleLine>
    );
}
