import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import {
  colors,
  Divider,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
//import { Tab, Tabs } from "@mui/base";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import "./RoomPage.css";
import { RoomSensor } from "../../Common/Types";
import { Metric, MetricDescription, MetricToPretty, Months, Seasons, TimeAggregationPeriod, Units, UnitToPretty, WeekDays } from "../../Common/Enums";
import { BigSwitch } from "../../components/BigSwitch";
import _, { forEach } from "lodash";
import MetricsDropdownSelect from "../../components/MetricsDropdownSelect";


ChartJS.register({ annotationPlugin });


const numberToMonth = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const numberToColor = {
  1: "#FF5733", // Red
  2: "#33FF57", // Green
  3: "#3357FF", // Blue
  4: "#F333FF", // Magenta
  5: "#33FFF6", // Cyan
  6: "#FF33A1", // Pink
  7: "#FFA533", // Orange
  8: "#A1FF33", // Lime
  9: "#5733FF", // Purple
  10: "#FF3333", // Light Red
  11: "#33FFAA", // Light Green
  12: "#3333FF", // Dark Blue
};

interface PatternsTabProps {
  roomId: string,
  selectedMetric: Metric,
  selectedSensorId: number,
  metricSensors: RoomSensor[],
  roomSensors: RoomSensor[],
  excludeAnomalies: boolean,
  dateFrom: Date,
  dateTo: Date,
  timeAggregation: TimeAggregationPeriod,
  metricSelected: (metric: Metric) => void
  sensorSelected: (sensorId: number) => void
  setExcludeAnomalies: Dispatch<SetStateAction<boolean>>
  setDateFrom: (dateFrom: Date) => void
  setDateTo: (dateTo: Date) => void
  setTimeAggregationPeriod: (timeAggregation: TimeAggregationPeriod) => void
}

export default function PatternsTab(props: PatternsTabProps) {
  const [excludeNonWorking, setExcludeNonWorking] = useState<boolean>(true);
  const [chart_1, setChart_1] = useState<{ labels: string[], units: Units, data: { label: string, data: (number | null)[] }[], optimal_bounds: number[], yAxesTitle: string }>({ labels: Array.from({ length: 24 }, (_, x) => `${x}:00`), data: [], optimal_bounds: [], units: Units.None, yAxesTitle: "" });
  const [chart_2, setChart_2] = useState<{ labels: string[], units: Units, data: (number | null)[], optimal_bounds: number[], yAxesTitle: string }>({ labels: [], data: [], optimal_bounds: [], units: Units.None, yAxesTitle: "" });

  useEffect(() => {
    const cancellationToken = new AbortController();
    if (!!props.selectedMetric && !!props.roomId) {
      fetch(
        `/api/rooms/${props.roomId}/patterns/hourly_average?metric_id=${props.selectedMetric}&exclude_anomalies=${props.excludeAnomalies}&sensor_id=${props.selectedSensorId}&time_aggregation=${props.timeAggregation}&date_from=${props.dateFrom.toLocaleDateString("sv")}&date_to=${props.dateTo.toLocaleDateString("sv")}&exclude_non_working_hours=${excludeNonWorking}`,
        {
          signal: cancellationToken.signal
        }
      )
        .then((response) => { if (response.ok) { return response.json(); } else { throw new Error(); } })
        .then((data: { units: Units, data_series: Record<string, number[]>, optimal_bounds: number[] }) => {
          setChart_1(s => ({
            ...s, data: Object.entries(data.data_series).map(x => ({ label: generateSingleLabel(props.timeAggregation, x[0]), data: x[1] })), optimal_bounds: data.optimal_bounds, units: data.units,
            yAxesTitle: `${props.selectedMetric !== Metric.None ? Metric[props.selectedMetric] : ""} ${UnitToPretty(data.units) ? `(${UnitToPretty(data.units)})` : ""}`
          }))
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    return () => { cancellationToken.abort(); setChart_1({ labels: Array.from({ length: 24 }, (_, x) => `${x}:00`), data: [], optimal_bounds: [], units: Units.None, yAxesTitle: "" }) };
  }, [props.roomId, props.selectedMetric, props.selectedSensorId, props.excludeAnomalies, excludeNonWorking, props.dateTo, props.dateFrom, props.timeAggregation]);


  useEffect(() => {
    const cancellationToken = new AbortController();
    if (!!props.selectedMetric && !!props.roomId) {
      fetch(
        `/api/rooms/${props.roomId}/patterns/average?metric_id=${props.selectedMetric}&exclude_anomalies=${props.excludeAnomalies}&sensor_id=${props.selectedSensorId}&time_aggregation=${props.timeAggregation}&date_from=${props.dateFrom.toLocaleDateString("sv")}&date_to=${props.dateTo.toLocaleDateString("sv")}&exclude_non_working_hours=${excludeNonWorking}`,
        {
          signal: cancellationToken.signal
        }
      )
        .then((response) => { if (response.ok) { return response.json(); } else { throw new Error(); } })
        .then((data: { units: Units, data: Record<string, number>, optimal_bounds: number[] }) => {
          setChart_2(s => ({
            ...s,
            labels: generateLabels(props.timeAggregation, data.data),
            data: generateData(props.timeAggregation, data.data),
            optimal_bounds: data.optimal_bounds,
            units: data.units,
            yAxesTitle: `${props.selectedMetric !== Metric.None ? Metric[props.selectedMetric] : ""} ${UnitToPretty(data.units) ? `(${UnitToPretty(data.units)})` : ""}`
          }));
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    return () => { cancellationToken.abort(); setChart_1({ labels: [], data: [], optimal_bounds: [], units: Units.None, yAxesTitle: "" }) };
  }, [props.roomId, props.selectedMetric, props.selectedSensorId, props.excludeAnomalies, excludeNonWorking, props.dateTo, props.dateFrom, props.timeAggregation]);

  return (<>
    <div className="flex w-full">
      <div className="flex-col grow-1 w-1/3 pr-5" >
        <div className="flex justify-start gap-8 my-3">
          <MetricsDropdownSelect selectedMetric={props.selectedMetric} metricSelected={props.metricSelected} roomSensors={props.roomSensors} />
          <p>Metric</p>
        </div>

        <div className="flex justify-start gap-8 my-3">
          <Select
            value={props.selectedSensorId}
            onChange={(event) => props.sensorSelected(event.target.value as number)}
            sx={{
              width: `185px`,
              ".MuiOutlinedInput-input": {
                padding: 0
              }
            }}
          >
            <MenuItem value={-1} key={-1}>All</MenuItem>
            {
              props.metricSensors.map(x => (<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>))
            }
          </Select>
          <p>Sensor</p>
        </div>

        <div className="flex justify-start gap-8 my-3 items-center">
          <div>
            <Select
              value={props.timeAggregation}
              onChange={(event) => props.setTimeAggregationPeriod(event.target.value)}
              sx={{
                width: `185px`,
                ".MuiOutlinedInput-input": {
                  padding: 0
                }
              }}
            >
              <MenuItem value={TimeAggregationPeriod.Day}>Day</MenuItem>
              <MenuItem value={TimeAggregationPeriod.Weekday}>Weekday</MenuItem>
              <MenuItem value={TimeAggregationPeriod.Week}>Week</MenuItem>
              <MenuItem value={TimeAggregationPeriod.Month}>Month</MenuItem>
              <MenuItem value={TimeAggregationPeriod.Season}>Season</MenuItem>
            </Select>
          </div>
          <p className="text-start">Time aggregation period</p>
        </div>


        <div className="flex justify-start gap-8 my-3">
          <div className="inline-block border-solid border-2 border-gray-500">
            <DatePicker
              showMonthDropdown
              showYearDropdown
              selected={props.dateFrom}
              onChange={(date) => props.setDateFrom(date ?? new Date())}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <p>Date: from</p>
        </div>

        <div className="flex justify-start gap-8 my-3">
          <div className="inline-block border-solid border-2 border-gray-500">
            <DatePicker
              showMonthDropdown
              showYearDropdown
              selected={props.dateTo}
              onChange={(date) => props.setDateTo(date ?? new Date())}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <p>Date: to</p>
        </div>

        <div className="flex justify-start gap-8 my-3">
          <Switch
            checked={props.excludeAnomalies}
            onChange={(_, checked) => props.setExcludeAnomalies(checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <p className="text-nowrap">Exclude anomalies</p>
        </div>

        <div className="flex justify-start gap-8 my-3">
          <Switch
            checked={excludeNonWorking}
            onChange={(_, checked) => setExcludeNonWorking(checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <p className="text-nowrap">Exclude non-working hours</p>
        </div>

        <p className="my-3 text-start">
          &lt;Metric description&gt;
          <br />
          {MetricDescription(props.selectedMetric)}
        </p>
      </div>

      <div className="flex-col grow-1 w-2/3">
        <p>Average hourly {MetricToPretty(props.selectedMetric)} of {TimeAggregationPeriod[props.timeAggregation]}</p>
        <Chart
          type="line"
          data={{
            labels: Array.from({ length: 24 }, (_, x) => `${x}:00`),
            datasets: chart_1.data,
          }}
          options={{
            scales: {
              y: {
                suggestedMin: 0,
                title: {
                  display: true,
                  text: chart_1.yAxesTitle,
                }
              },
            },
            plugins: {
              legend: {
                display: false
              },
              annotation: chart_1.optimal_bounds && chart_1.optimal_bounds.length === 2 ? {
                annotations: {
                  line1: {
                    drawTime: "afterDatasetsDraw",
                    borderColor: "rgba(0, 255, 255, 0.25)",
                    backgroundColor: "rgba(0, 255, 255, 0.25)",
                    type: "box",
                    yMin: chart_1.optimal_bounds[0],
                    yMax: chart_1.optimal_bounds[1],
                    borderWidth: 1
                  },
                },
              } : {},
            },
          }}
        />
        <p>Average {MetricToPretty(props.selectedMetric)} by {TimeAggregationPeriod[props.timeAggregation]}</p>
        <Chart
          type="bar"
          data={{
            labels: chart_2.labels,
            datasets: [{ data: chart_2.data }],
          }}
          options={{
            scales: {
              y: {
                title: {
                  display: true,
                  text: chart_1.yAxesTitle,
                },
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          }}
        />
      </div>
    </div >
  </>);
}

function generateLabels(timeAggregation: TimeAggregationPeriod, input: Record<string, number>): string[] {
  switch (timeAggregation) {
    case TimeAggregationPeriod.Month:
      return _.range(1, 13).map(x => Months[x]);
    case TimeAggregationPeriod.Season:
      return _.range(1, 5).map(x => Seasons[x]);
    case TimeAggregationPeriod.Weekday:
      return _.range(1, 8).map(x => WeekDays[x]);
    case TimeAggregationPeriod.Day:
      const dates = Object.entries(input).map(x => ({ date: new Date(x[0]), value: x[1] }));
      const minDate = _.min(dates.map(x => x.date)) ?? 0;
      const maxDate = _.max(dates.map(x => x.date)) ?? 0;

      const resultD = new Array<string>(0);
      let currentDate = new Date(minDate);

      while (currentDate <= maxDate) {
        resultD.push(currentDate.toLocaleDateString("sv"));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return resultD;
    case TimeAggregationPeriod.Week:
      const parsed = Object.entries(input).map(x => [parseInt(x[0].split(':')[0]), x[1]]);
      const min = _.min(parsed.map(x => x[0])) ?? 0;
      const max = _.max(parsed.map(x => x[0])) ?? 0;
      return Array.from(new Array(max - min + 1), (x, i) => `Week ${i + min}`);
    // return Object.entries(numberToMonth).toSorted((a, b) => parseInt(a[0]) - parseInt(b[0])).map(x => x[1]);
    default:
      return [];
  }
}

function generateSingleLabel(timeAggregation: TimeAggregationPeriod, input: string): string {
  switch (timeAggregation) {
    case TimeAggregationPeriod.Month:
      return Months[parseInt(input)];
    case TimeAggregationPeriod.Season:
      return Seasons[parseInt(input)];
    case TimeAggregationPeriod.Weekday:
      return WeekDays[parseInt(input)];
    case TimeAggregationPeriod.Day:
      return new Date(input).toLocaleDateString("sv");
    case TimeAggregationPeriod.Week:
      return `Week ${parseInt(input.split(':')[0])}`;
    default:
      return "";
  }
}

function generateData(timeAggregation: TimeAggregationPeriod, input: Record<string, number>): (number | null)[] {

  switch (timeAggregation) {
    case TimeAggregationPeriod.Month:
      const resultM = new Array<number | null>(12).fill(null);

      if (!!input) {
        Object.entries(input).forEach(kvp => {
          resultM[parseInt(kvp[0]) - 1] = kvp[1]
        });
      }
      return resultM;
    case TimeAggregationPeriod.Season:
      const resultS = new Array<number | null>(4).fill(null);

      Object.entries(input).forEach(kvp => {
        resultS[parseInt(kvp[0]) - 1] = kvp[1]
      });
      return resultS;
    case TimeAggregationPeriod.Weekday:
      const resultWd = new Array<number | null>(4).fill(null);

      Object.entries(input).forEach(kvp => {
        resultWd[parseInt(kvp[0]) - 1] = kvp[1]
      });
      return resultWd;
    case TimeAggregationPeriod.Day:
      const dates = Object.entries(input).map(x => ({ date: new Date(x[0]), value: x[1] }));
      const minDate = _.min(dates.map(x => x.date)) ?? 0;
      const maxDate = _.max(dates.map(x => x.date)) ?? 0;

      const resultD = new Array<number | null>(0);
      let currentDate = new Date(minDate);

      while (currentDate <= maxDate) {
        resultD.push(dates.find(x => x.date.toLocaleDateString("sv") === currentDate.toLocaleDateString("sv"))?.value ?? null);
        currentDate.setDate(currentDate.getDate() + 1);
      }


      return resultD;

    case TimeAggregationPeriod.Week:
      const parsed = Object.entries(input).map(x => [parseInt(x[0].split(':')[0]), x[1]]);
      const min = _.min(parsed.map(x => x[0])) ?? 0;
      const max = _.max(parsed.map(x => x[0])) ?? 0;

      const resultW = new Array<number | null>(max - min + 1).fill(null);

      parsed.forEach(kvp => {
        resultW[kvp[0] - min] = kvp[1]
      });
      return resultW;

    //return inputLabels;
    // return Object.entries(numberToMonth).toSorted((a, b) => parseInt(a[0]) - parseInt(b[0])).map(x => x[1]);
    default:
      return [];
  }
}



function isTemperatureOutlier(temperature: number): boolean {
  return temperature > 45;
}

function temperaturePointStyleMapping(ctx: any) {
  if (ctx.parsed && ctx.parsed.y) {
    return isTemperatureOutlier(ctx.parsed.y) ? 'crossRot' : 'circle'
  }
  return false;
}

function temperaturePointBorderWidthMapping(ctx: any) {
  if (ctx.parsed && ctx.parsed.y) {
    return isTemperatureOutlier(ctx.parsed.y) ? 3 : 0;
  }
  return 0;
}

function temperaturePointRadiusMapping(ctx: any) {
  if (ctx.parsed && ctx.parsed.y) {
    return isTemperatureOutlier(ctx.parsed.y) ? 5 : 0;
  }
  return 0;
}


